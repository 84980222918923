import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router';
import withRouter from 'src/polyfils/customRouter';
import { contextPath } from 'src/constants';
import { Note, Store, Phone, Calendar, InfoCircle, CheckCircle } from '../../commonComponents/icons/icon';
import Card from '../../commonComponents/uiComponents/LDCard';
import CardItem from '../../commonComponents/uiComponents/LDCardItem';
import Divider from '../../commonComponents/uiComponents/LDDivider';
import Button from '../../commonComponents/uiComponents/LDButton';
import Modal from '../../commonComponents/uiComponents/LDModal';
import { useMutation, useQuery } from 'react-query';
import SpaceDonationService, { Service } from 'src/spaceDonationService';
import LoaderComponent from '../../commonComponents/appComponents/loader/LoaderComponent';
import { formatPhoneNumber } from 'src/components/common/utils';
import Feedback from '../../scenes/reservations/checkout/checkoutFeedback';
import { convertDateToTimeZone } from 'src/components/spacedonation/scenes/common/timeZoneHelperUtil';
import './styles.css';
import moment from 'moment';
import { toCapitalize, } from '../../commonComponents/common/utils';
import { Tooltip } from "@mui/material";
import { LITERATURE_RULE, TERMS_OF_PERMITTED_USE_URL, WALMART_NONPROFITS_URL } from '../../constants/constants';

const service: Service = SpaceDonationService;

const reservationContentData = {
    dateTime: {
        icon: <Calendar />,
        header: 'Date and Time'
    },
    spaceDetails: {
        icon: <Store />,
        header: 'Store and Space Location'
    },
    contactDetails: {
        icon: <Phone />,
        header: 'Main Contact'

    },
    eventDetails: {
        icon: <Note />,
        header: 'Event Details'
    }
}

const CANCEL = 'Cancel';
const CHECKIN = 'checkIn';
const CHECKOUT = 'checkOut';
const CHECKED_OUT = 'CHECKED_OUT';
const CHECKED_IN = 'CHECKED_IN';
// const APPROVED = 'APPROVED';

// tslint:disable-next-line
export interface ReservationDetailsProps {
    match: any
}

// tslint:disable-next-line
export interface ReservationDetailsState {
}

export interface ActionSectionProps {
    showCancelButton: any,
    showCheckInButton: any,
    showCheckoutButton: any,
    setActionSuccess: any,
}

class ReservationDetails extends React.Component<ReservationDetailsProps, ReservationDetailsState> {
    public render() {
        // @ts-ignore
        return this.props.location && this.props.location.state && this.props.location.state.spaceDonation ?
            // @ts-ignore
            <ReservationContent history={this.props.history}
                // @ts-ignore
                spaceDonationProp={this.props.location.state.spaceDonation}
                // @ts-ignore
                waaSTaskMessageProp={this.props.location.state.waaSTaskMessage} />
            : // @ts-ignore
            <Navigate to={`${contextPath}/space-donation/events`} />
        // <div>{alert(props.location.state.eventId)}</div>
    }
}

// @ts-ignore
export default withRouter(ReservationDetails);


export interface ReservationContentProps {
    history: any[]
}

const ReservationContent: React.SFC<ReservationContentProps> = (props: any) => {
    const [spaceDonation, setSpaceDonation] = useState(props.spaceDonationProp);
    const [waaSTaskMessage, setWaaSTaskMessage] = useState(props.waaSTaskMessageProp);
    const [, setActionSuccess] = useState(null);
    const [showCancelButton, setShowCancelButton] = useState(false);
    const [showCheckInButton, setShowCheckInButton] = useState(false);
    const [showCheckoutButton, setShowCheckoutButton] = useState(false);

    useEffect(() => {
        if (waaSTaskMessage && waaSTaskMessage.formProperties) {
            setShowCancelButton(waaSTaskMessage.formProperties.includes(CANCEL) ? true : false);
            setShowCheckInButton(waaSTaskMessage.formProperties.includes(CHECKIN) ? true : false);
            setShowCheckoutButton(waaSTaskMessage.formProperties.includes(CHECKOUT) ? true : false);
        }
    }, []);

    useEffect(() => {
        if (waaSTaskMessage && waaSTaskMessage.formProperties) {
            setShowCancelButton(waaSTaskMessage.formProperties.includes(CANCEL) ? true : false);
            setShowCheckInButton(waaSTaskMessage.formProperties.includes(CHECKIN) ? true : false);
            setShowCheckoutButton(waaSTaskMessage.formProperties.includes(CHECKOUT) ? true : false);
        } else {
            setShowCancelButton(false);
            setShowCheckInButton(false);
            setShowCheckoutButton(false);
        }
    }, [waaSTaskMessage]);


    return <div className='reservation-card'><div className='reservation-card-headers'>
        <div className='reservation-card-section-headers black-text'>Event Details</div>
        <div className='reservation-card-go-back' onClick={() => props.history.push(`${contextPath}/space-donation/events`)}>Back</div>
    </div>

        <Card className='reservation-content-card'>
            <CardItem className='reservation-content-card-date-time'>
                <div className='reservation-content-card-header-section'>
                    <div className='reservation-content-card-header-icon'>{reservationContentData.dateTime.icon}</div>
                    <div className='reservation-content-card-header'>{reservationContentData.dateTime.header}</div>
                </div>

                <div className='reservation-content-card-details-setion'>
                    <div />
                    <div className='reservation-content-card-details'>
                        <div>{moment(
                            convertDateToTimeZone(spaceDonation.eventSlots[0].slotStartTime, spaceDonation?.storeInfo?.timeZoneCode))
                            .format('MMM Do, YYYY')
                        } ({spaceDonation?.storeInfo?.timeZoneCode})</div>
                        {spaceDonation.eventSlots.sort((a: any, b: any) => a.slotId - b.slotId).map((slot: any) => {
                            return <div key={slot.name}>
                                {slot?.label?.toUpperCase()}
                            </div>
                        })}
                        <br />
                    </div>
                </div>

            </CardItem>
            <Divider />
            <CardItem className='reservation-content-card-space-section'>
                <div className='reservation-content-card-header-section'>
                    <div className='reservation-content-card-header-icon'>{reservationContentData.spaceDetails.icon}</div>
                    <div className='reservation-content-card-header'>{reservationContentData.spaceDetails.header}</div>
                </div>

                <div className='reservation-content-card-details-setion'>
                    <div />
                    <div className='reservation-content-card-details'>
                        <div>{spaceDonation.storeInfo.city} - {spaceDonation.storeNbr}</div>
                        <div>{spaceDonation.storeInfo.addressLine1}</div>
                        <div>{spaceDonation.storeInfo.addressLine2}</div>
                        <div>{spaceDonation.storeInfo.state} {spaceDonation.storeInfo.postalCode}</div>
                        <div>{spaceDonation.spaceLocation}</div>
                        <br />
                    </div>
                </div>

            </CardItem>
            <Divider />

            <CardItem className='reservation-content-card-space-section'>
                <div className='reservation-content-card-header-section'>
                    <div className='reservation-content-card-header-icon'>{reservationContentData.contactDetails.icon}</div>
                    <div className="reservation-confirmation-content-card-header">Event Contact Information &nbsp;
                    <Tooltip
                    title={
                        <div className="tooltip-contacts-wrapper">
                        <span className="tooltip-contacts">
                            Only the <strong>Organization Contact(s)</strong> are
                            able to modify or cancel reservations in the Spark Good
                            Space Request Tool at{" "}
                            <a
                            href={WALMART_NONPROFITS_URL}
                            target="_blank"
                            rel="noopener noreferrer"
                            >
                            www.walmart.com/nonprofits
                            </a>
                            . If you need to update the organization contact, please
                            make changes to your DEED account.
                        </span>
                        <br />
                        <span className="tooltip-contacts">
                            <strong>Event Contact(s)</strong> listed for the event
                            are only able to check-in and check-out for the event
                            and cannot make modifications to the event and cancel
                            the reservation.
                        </span>
                        </div>
                    }
                    placement="right"
                    >
                    <span>
                        <InfoCircle className="icon-info-contacts" />
                    </span>
                    </Tooltip>
                    </div>
                </div>
                <div className='reservation-confirmation-content-multiple-contact'>
                    <div />
                    <div className='reservation-confirmation-content-card-details-multiple-contact'>
                        {(spaceDonation.eventContacts || []).map((eventContacts: any, index: any) => (
                            <div key={index}>
                                <div>{toCapitalize(eventContacts?.firstName)} {toCapitalize(eventContacts?.lastName)}</div>
                                <div>{eventContacts?.email}</div>
                                <div>{formatPhoneNumber(eventContacts?.phoneNumber)}</div>
                                <br />
                            </div>
                        ))}
                    </div>
                </div>
                {spaceDonation.eventContact &&
                    <div className='reservation-content-card-details'>
                        <div>{toCapitalize(spaceDonation.eventContact.firstName)}
                            {toCapitalize(spaceDonation.eventContact.lastName)}
                        </div>
                        <div>{spaceDonation.eventContact.email}</div>
                        <div>{formatPhoneNumber(spaceDonation.eventContact.phoneNumber)}</div>
                        <br />
                    </div>
                }
            </CardItem>
            <Divider />
            <CardItem className='reservation-content-card-space-section'>
                <div className='reservation-content-card-header-section'>
                    <div className='reservation-content-card-header-icon'>{reservationContentData.eventDetails.icon}</div>
                    <div className='reservation-content-card-header'>{reservationContentData.eventDetails.header}</div>
                </div>

                <div className='reservation-content-card-details-setion'>
                    <div />
                    <div className='reservation-content-card-details'>
                        <div>{spaceDonation.eventPurpose}</div>
                        <div>{spaceDonation.focusArea}</div>
                        <div>{spaceDonation.aboutEvent}</div>
                        <div>{spaceDonation.walmartPropertyDetails}</div>
                        <div>{spaceDonation.exchangeDonationDetails}</div>
                        <br />
                    </div>
                </div>

            </CardItem>
            {props.spaceDonationProp.status === CHECKED_IN &&
                <div className='links-wrapper'>
                    <a target='_blank' href={TERMS_OF_PERMITTED_USE_URL} rel="noreferrer">
                        Terms of Permitted Use</a>
                    <a target='_blank' href={LITERATURE_RULE} rel="noreferrer">
                        Notice of Solicitation and Distribution of Literature Rules</a>
                </div>
            }
            <Divider />
            <ActionSection showCancelButton={showCancelButton}
                setActionSuccess={setActionSuccess}
                showCheckInButton={showCheckInButton}
                showCheckoutButton={showCheckoutButton}
                spaceDonation={spaceDonation}
                waaSTaskMessage={waaSTaskMessage}
                setSpaceDonation={setSpaceDonation}
                setWaaSTaskMessage={setWaaSTaskMessage}
                {...props} />
        </Card>
    </div>
}


const ActionSection: React.SFC<ActionSectionProps> = (props: any) => {
    const [action, setAction] = useState(null);
    const [showCheckinPopup, setShowCheckinPopup] = useState(false);
    const [showCheckoutPopUp, setshowCheckoutPopUp] = useState(false);
    const [showCancelPopUp, setShowCancelPopUp] = useState(false);
    const [showCancelConfirm, setShowCancelConfirm] = useState(false);
    const [showSurveyPopUp, setShowSurveyPopUp] = useState(false);
    const mutation = useMutation((data: any) => service.updateEvent(data));
    const diffDays = moment().diff(moment(props.spaceDonationProp.checkOutDateTime), 'days');
    useEffect(() => {
        if (mutation.isSuccess) {
            props.setActionSuccess(action);
            props.setSpaceDonation(mutation.data.spaceDonationTaskDTOS[0].spaceDonation);
            props.setWaaSTaskMessage(mutation.data.spaceDonationTaskDTOS[0].waaSTaskMessage)
            if (action === CHECKIN) {
                setShowCheckinPopup(true);
            } else if (action === CHECKOUT) {
                setshowCheckoutPopUp(true);
            } else if (action === CANCEL) {
                setShowCancelPopUp(true);
            }
            props.history.push({
                pathname: `${contextPath}/space-donation/events/detail`,
                state: {
                    spaceDonation: mutation.data.spaceDonationTaskDTOS[0].spaceDonation,
                    waaSTaskMessage: mutation.data.spaceDonationTaskDTOS[0].waaSTaskMessage
                }
            })
        }
    }, [mutation.isSuccess]);
    return <>{mutation.isLoading && <LoaderComponent />}
        <CheckinModalCard style={{ 'width': '18vw !important' }} isOpen={showCheckinPopup} setIsOpen={setShowCheckinPopup} />
        <CheckOutModalCard style={{ 'width': '18vw !important' }} isOpen={showCheckoutPopUp} spaceDonation={props.spaceDonationProp}
            {...props} setIsOpen={setshowCheckoutPopUp} />
        <CheckOutModalCard style={{ 'width': '18vw !important' }} isOpen={showSurveyPopUp} spaceDonation={props.spaceDonationProp}
            feedbacksurvey={true}
            {...props} setIsOpen={setShowSurveyPopUp} />
        <CancelModalCard style={{ 'width': '18vw !important' }} isOpen={showCancelPopUp} setIsOpen={setShowCancelPopUp} />
        <CancelConfirm isOpen={showCancelConfirm} setIsOpen={setShowCancelConfirm}
            task={() => performtask(props, CANCEL, mutation, setAction)} />
        <CardItem className='reservation-content-card-buttons'>
            {props.showCancelButton ? <div
                className='reservation-content-card-buttons-cancel'
                onClick={() => setShowCancelConfirm(true)} >
                Cancel Event
            </div>
                :
                <div className='reservation-content-card-buttons-cancel' />
            }

            <div className='reservation-content-card-buttons-checkIn'>
                {props.spaceDonationProp.status === CHECKED_OUT && diffDays < 31 && <div><Button
                    className='variant-button'
                    onClick={() => { setShowSurveyPopUp(true); }} variant='primary'>
                    View Rating
                </Button>
                    <Button
                        onClick={() => {
                            props.history.push({
                                pathname: `${contextPath}/space-donation/impact-survey`,
                                state: {
                                    spaceDonation: props.spaceDonation,
                                    waaSTaskMessage: props.waaSTaskMessage
                                }
                            });
                        }} variant='primary'>
                        Take Impact Survey
                    </Button></div>}
                {props.showCheckInButton ?
                    <CheckInButton {...props} mutation={mutation} setAction={setAction} />
                    :
                    <div />}
                {props.showCheckoutButton ?
                    <Button
                        onClick={() => { performtask(props, CHECKOUT, mutation, setAction) }} variant='primary'>
                        Check Out
                    </Button> :
                    <div />}
            </div>
        </CardItem></>
}

const performtask = (props: any, action: string, mutation: any, setAction: any) => {

    const data = [{
        spaceDonation: props.spaceDonation,
        waaSTask: { ...props.waaSTaskMessage, variables: { userAction: action.toLocaleLowerCase(), user: props.spaceDonation.cid } }
    }];
    setAction(action);
    mutation.mutate(data);
}


const CheckInButton: React.SFC = (props: any) => {
    const { status, data, isFetching, } = useQuery(['fetchCheckInHours', { key: 'checkInPriorHours', countryCode: 'US' }],
        service.getSpaceDonationConfigurations, { refetchOnWindowFocus: false, enabled: true });
    const checkInTime = moment(props.spaceDonation.checkInDateTime);
    const checkOutTime = moment(props.spaceDonation.checkOutDateTime);
    const [disableCheckInButton, setDisableCheckInButton] = useState(true);
    useEffect(() => {
        if (status === 'success') {
            const checkFunction = () => {
                const time = moment.duration(data?.default + ':00:00');
                const exactCheckinTime = moment(checkInTime).subtract(time);
                if (moment() > exactCheckinTime && moment() < checkOutTime) {
                    setDisableCheckInButton(false);
                }
            };
            const interval = setInterval(checkFunction, 60000);
            checkFunction();
            return () => clearInterval(interval);
            // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
        }
        else {
            return setDisableCheckInButton(true);
        }
    }, [isFetching]);
    return <Button disabled={disableCheckInButton}
        onClick={() => { performtask(props, CHECKIN, props.mutation, props.setAction) }} variant='primary'>
        Check In
    </Button>
}

const CheckOutModalCard: any = (props: any) => {
    return <Modal isOpen={props.isOpen} onClose={() => props.setIsOpen(false)}
        size='small' title=' '>
        <Feedback setSpaceDonation={props.setSpaceDonation} eventPurpose={props.spaceDonation.eventPurpose}
            feedbacksurvey={props.feedbacksurvey}
            store={props.spaceDonation.storeInfo}
            countryCode={props.spaceDonation.countryCode}
            feedBack={props.spaceDonation.feedback} donationId={props.spaceDonation.id} />
    </Modal>
}

const CheckinModalCard: any = (props: any) => {
    return <Modal isOpen={props.isOpen} onClose={() => props.setIsOpen(false)}
        size='small' title=' '>
        <div className='checkin-message'>
            <CheckCircle className='checkin-icon' />
            <h3>You are checked in!</h3>
            <br />
            <h6>Please walk to Customer Service. The store manager will see you shortly.</h6>
            <br />
            <Button
                onClick={() => props.setIsOpen(false)} variant='secondary'>
                Back
            </Button>
        </div>
    </Modal>
}

const CancelModalCard: any = (props: any) => {
    return <Modal isOpen={props.isOpen} onClose={() => props.setIsOpen(false)}
        size='small' title=' '>
        <div className='checkin-message'>
            <CheckCircle className='checkin-icon' />
            <h3>Cancellation Success!</h3>
            <br />
            <br />
            <Button
                onClick={() => props.setIsOpen(false)} variant='secondary'>
                Back
            </Button>
        </div>
    </Modal>
}


const CancelConfirm: any = (props: any) => {
    return <Modal isOpen={props.isOpen} onClose={() => props.setIsOpen(false)}
        size='small' title=' '>
        <div className='checkin-message'>
            <h3>Confirm Cancellation</h3>
            <br />
            <br />
            <div className='cancel-confirmation-buttons'>
                <Button
                    onClick={() => props.setIsOpen(false)} variant='secondary'>
                    Back
                </Button>
                <Button
                    onClick={() => { props.setIsOpen(false); props.task(); }} variant='primary'>
                    Yes
                </Button>
            </div>
        </div>
    </Modal>
}