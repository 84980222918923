import { useEffect, useState, useContext } from 'react';
import InfoCard from '../../commonComponents/appComponents/accountInfoCard/accountInfoCard';
import InfoEditCard from '../../commonComponents/appComponents/accountInfoCard/accountInfoEditCard';
import InfoCardtextItem from '../../commonComponents/appComponents/accountInfoCard/accountInfoCardTextItem';
import { useMutation, useQuery } from 'react-query';
import Select from '../../commonComponents/uiComponents/LDSelect';
import SpaceDonationService, { Service } from 'src/spaceDonationService';
import LoaderComponent from '../../commonComponents/appComponents/loader/LoaderComponent';
import { useForm, Controller } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import Button from '../../commonComponents/uiComponents/LDButton';
import Modal from '../../commonComponents/uiComponents/LDModal';
import LDCheckbox from '../../commonComponents/uiComponents/LDCheckBox';
import { validateEmail, isNotEmpty, toCapitalize, validatePhoneNumber} from './../../commonComponents/common/utils';
import UserContext from '../../store/user-context';
import { CheckCircle, CloseCircleFill } from '../../commonComponents/icons/icon';
import analytics from '../../../../adobe-analytics/analytics';
import './styles.css';
import { SPACEANALYTICS } from '../../../../adobe-analytics-space/analytics-space-dataelements';
import { CustomContactInfo } from './CustomContactInfo';
import { DEED_LOGIN_URL } from '../../constants/constants';


const service: Service = SpaceDonationService;



const Account = () => {
    useEffect(() => {
        analytics.trackPageInfoSpaceExternal(SPACEANALYTICS.displayUserAccountSpaceExternalPage);
    }, []);
    const userContext = useContext(UserContext);
    const deleteMutation = useMutation((d: any) => service.deleteSecondaryContact(d));
    const { data: secondaryContacts, isFetching: secondaryContactsIsFetching, refetch } =
        // @ts-ignore
        useQuery(['getSecondaryContact', { orgId: userContext?.organization?.id }],
            service.getSecondaryContact, { refetchOnWindowFocus: false });
    // @ts-ignore
    const orgId = userContext?.organization?.id;
    const [data, setData] = useState({});
    const [showSuccessPopUp, setShowSuccessPopUp] = useState(false);
    const [showErrorPopUp, setShowErrorPopUp] = useState(false);
    const [showSuccessConfirm, setShowSuccessConfirm] = useState(false);
    const [error, setError] = useState({});

    const mutation = useMutation((id: any) => service.fetchUserDetails(id));
    useEffect(() => {
        mutation.mutate(orgId);
    }, []);
    useEffect(() => {
        setData(mutation.data)
    }, [mutation.isSuccess]);

    return <>
        <SuccessModalCard style={{ 'width': '18vw !important' }} isOpen={showSuccessPopUp} setIsOpen={setShowSuccessPopUp} />
        <ErrorModalCard style={{ 'width': '18vw !important' }} isOpen={showErrorPopUp} error={error} setIsOpen={setShowErrorPopUp} />
        <SuccessConfirm style={{ 'width': '18vw !important' }} isOpen={showSuccessConfirm} setIsOpen={setShowSuccessConfirm}/>
        {secondaryContactsIsFetching && <LoaderComponent />}
        {mutation.isLoading && !mutation.isSuccess && <LoaderComponent />}
        {!mutation.isLoading && mutation.isSuccess && <AccountSection
            setData={setData} data={data}
            setShowSuccessPopUp={setShowSuccessPopUp}
            setShowErrorPopUp={setShowErrorPopUp}
            setError={setError}
            userContext={userContext}
            secondaryContacts={secondaryContacts}
            deleteMutation={deleteMutation}
            refetch={refetch}
            setShowSuccessConfirm={setShowSuccessConfirm}
        />}
    </>
}


export default Account;


const AccountSection = (props: any) => {
    return <div className='account-info-card-container'><div className='row'>
        <PersonalInfo {...props} />
        <OrgInfo {...props} />
        {props.userContext?.organization?.cybergrantsOrganizationId ? <SecondaryInfoVerified {...props} />
        :   <SecondaryInfo {...props}  />}
        {props && props.data && <ContactInfo {...props} />}
        {props && <CustomContactInfo {...props} />}
    </div>
        {/* <div className='row'>
            <SecondaryContactInfo />
            <Communications />
        </div> */}
    </div>
}


const PersonalInfo = (props: any) => {
    const [editButtonClicked, setEditButtonClicked] = useState(false);
    const myCId = props?.userContext?.organization?.userContactDetails?.cid;
    const fnmae = props?.data?.contactList?.filter((x: any) => x.cid === myCId)[0].firstName;
    const lName = props?.data?.contactList?.filter((x: any) => x.cid === myCId)[0].lastName;
    const email = props?.data?.contactList?.filter((x: any) => x.cid === myCId)[0].email;
    const phoneNumber = props?.data?.contactList?.filter((x: any) => x.cid === myCId)[0].phoneNumber;
    return <div className='col-lg-6 col-md-12 col-xs-12 col-sm-12'>
        {!editButtonClicked && <InfoCard
            header='Your Personal Information'
            footer={props.userContext?.organization?.cybergrantsOrganizationId ? <div>To edit your personal information, sign in to your<br />
                <a href={DEED_LOGIN_URL}
                    target='_blank' rel="noreferrer"> DEED account</a></div>
                :
                <div/>}>
            <InfoCardtextItem
                header='Full Name'>{toCapitalize(fnmae)} {toCapitalize(lName)}
            </InfoCardtextItem>
            <InfoCardtextItem
                header='Email'>{email}</InfoCardtextItem>
            <InfoCardtextItem showEditButton={true} buttonHandler={setEditButtonClicked}
                header='Phone'>{getPhoneNumber(phoneNumber)}</InfoCardtextItem>
        </InfoCard>}
        {editButtonClicked && <PrimaryInfoEditForm setEditButtonClicked={setEditButtonClicked} {...props} />}
    </div>
}

const getPhoneNumber = ((num: any) => {
    let match: any = false;
    if (num?.length === 10) {
        match = num?.match(/^(\d{3})(\d{3})(\d{3,4})$/);
    }
    if (match) {
        return `+1 (${match[1]}) ${match[2]}-${match[3]}`;
    }
    return num;
})

const SecondaryInfo = (props: any) => {
    const [editButtonClicked, setEditButtonClicked] = useState(false);
    const isDeedUser = props.userContext?.organization?.cybergrantsOrganizationId
    const myCId = props?.userContext?.organization?.userContactDetails?.cid;
    const phonenumber = props?.data?.contactList.filter((x: any) => x.cid !== myCId)[0].phoneNumber;
    const fname = props?.data?.contactList.filter((x: any) => x.cid !== myCId)[0]?.firstName;
    const lname = props?.data?.contactList.filter((x: any) => x.cid !== myCId)[0]?.lastName;
    const email = props?.data?.contactList.filter((x: any) => x.cid !== myCId)[0]?.email;
    return <div className='col-lg-6 col-md-12 col-xs-12 col-sm-12'>
        {!editButtonClicked && <InfoCard
            showEditButton={!isDeedUser}
            buttonHandler={setEditButtonClicked}
            header= 'Organization Secondary Contact Info'
            footer={<div />}>
            <InfoCardtextItem
                header='Full Name'>{toCapitalize(fname)} {toCapitalize(lname)}
            </InfoCardtextItem>
            <InfoCardtextItem
                header='Email'>{email}</InfoCardtextItem>
            <InfoCardtextItem
                header='Phone'>{getPhoneNumber(phonenumber)}</InfoCardtextItem>
        </InfoCard>}
        {editButtonClicked && <SecondaryInfoEditForm isOrgVerified={!isDeedUser}
            setEditButtonClicked={setEditButtonClicked} {...props} />}
    </div>
}

const SecondaryInfoVerified = (props: any) => {
    const myMail = props?.userContext?.organization?.userContactDetails?.email;
    // filter((x:any) => myCId !== x.cid).
    const [mail, setMail] = useState(myMail);
    const phonenumber = props?.data?.contactList?.filter((x: any) => x.email === mail)[0]?.phoneNumber;
    const fname = props?.data?.contactList?.filter((x: any) => x.email === mail)[0]?.firstName;
    const lname = props?.data?.contactList?.filter((x: any) => x.email === mail)[0]?.lastName;
    const email = props?.data?.contactList?.filter((x: any) => x.email === mail)[0]?.email;
    return <div className='col-lg-6 col-md-12 col-xs-12 col-sm-12'>
        <InfoCard
            showEditButton={false}
            header='Organization Alternative Contacts'
            footer={<div />}>
            <Select
                value={mail}
                label={<div className='space-request-questions-card-answer-text'>Select a Contact</div>}
                onChange={(event: any) => {
                    setMail(event.target.value);
                }}>
                {props?.userContext?.organization?.orgContactList?.map((fa: any) => {
                    return <option key={fa} value={fa.email} selected={myMail === fa.email}>
                        {`${toCapitalize(fa.firstName)} ${toCapitalize(fa.lastName)}`}
                    </option>
                })}
            </Select>
            <InfoCardtextItem
                header='Full Name'>{toCapitalize(fname)} {toCapitalize(lname)}
            </InfoCardtextItem>
            <InfoCardtextItem
                header='Email'>{email}</InfoCardtextItem>
            <InfoCardtextItem
                header='Phone'>{getPhoneNumber(phonenumber)}</InfoCardtextItem>
        </InfoCard>
    </div>
}

const OrgInfo = (props: any) => {
    const [editButtonClicked, setEditButtonClicked] = useState(false);
    const isOrgVerified = props.userContext?.organization?.isVerified;
    const isDeedUser = props.userContext?.organization?.cybergrantsOrganizationId;
    return <div className='col-lg-6 col-md-12 col-xs-12 col-sm-12'>
        {!editButtonClicked && <InfoCard
            footer={isDeedUser ? <div>To edit your organization information, sign in to your<br /><a href={DEED_LOGIN_URL}
                target='_blank' rel="noreferrer"> DEED account</a></div>
                :
                <div />}
            showEditButton={!isDeedUser}
            buttonHandler={setEditButtonClicked}
            header='Your Organization'>
            <InfoCardtextItem header='Organization'>{toCapitalize(props?.data?.orgName)}</InfoCardtextItem>
            <InfoCardtextItem header='Organization type'
                showEditButton={isDeedUser}
                buttonHandler={setEditButtonClicked}>{toCapitalize(props?.data?.focusArea)}</InfoCardtextItem>
            <InfoCardtextItem header='Organization Entity'
                showEditButton={false} buttonHandler={setEditButtonClicked}
            >{toCapitalize(isDeedUser ? (isOrgVerified ? "verified" : "non verified" ) : props?.data?.orgEntity)}</InfoCardtextItem>
            <InfoCardtextItem header='Organization Website'
                showEditButton={isDeedUser} buttonHandler={setEditButtonClicked}
            ><a href={props?.data?.website} />{props?.data?.website}<a /></InfoCardtextItem>
            <InfoCardtextItem header='Address'><div>{props?.data?.orgAddressLine1}<br />
                {props?.data?.orgAddressLine2}<br />{props?.data?.orgState} , {props?.data?.orgZip}</div></InfoCardtextItem>
        </InfoCard>}

        {editButtonClicked && <OrgInfoEditForm isOrgVerified={isOrgVerified} isDeedUser={isDeedUser}
            setEditButtonClicked={setEditButtonClicked} {...props} />}
    </div>
}

const ContactInfo = (props: any) => {
    const [enableEmail, setEnableEmail] = useState(props?.data?.contactList
        .filter((x: any) => x.cid === props?.userContext?.organization?.userContactDetails?.cid)[0]?.permissionToEmail);
    const [clicked, setClicked] = useState(false);
    const _mutation = useMutation((data: any) => service.updateOrgInfo(data));
    useEffect(() => {
        if (_mutation.isSuccess) {
            props.setData(_mutation.data);
            props.setShowSuccessPopUp(true);
        }
    }, [_mutation.isSuccess]);

    useEffect(() => {
        if (props && props.data && clicked) {
            const existingObj = props.data;
            const primaryContact = existingObj?.contactList
                ?.filter((x: any) => x.cid === props?.userContext?.organization?.userContactDetails?.cid)[0];
            const otherContacts = existingObj?.contactList
                ?.filter((x: any) => x.cid !== props?.userContext?.organization?.userContactDetails?.cid);
            primaryContact.permissionToEmail = enableEmail;
            const updatedContactDetails = [primaryContact, ...otherContacts];
            existingObj.existingObj = updatedContactDetails;
            _mutation.mutate(existingObj);
        }

    }, [enableEmail])

    useEffect(() => {
        if (_mutation.isError) {
            props.setShowErrorPopUp(true);
            props.setError(_mutation?.error);
        }
    }, [_mutation.isError]);
    return <div className='col-lg-6 col-md-12 col-xs-12 col-sm-12'>
        {_mutation.isLoading && <LoaderComponent />}
        <InfoCard
            showEditButton={false}
            buttonHandler={false}
            header='Communications'
        >
            Choose how we can send updates about your requests and events
            <br />
            <InfoCardtextItem header=''><div className='account-info-card-email'><LDCheckbox
                label='Receive email notifications'
                checked={enableEmail}
                onChange={() => {
                    setClicked(true);
                    setEnableEmail(!enableEmail);
                }}
            /></div></InfoCardtextItem>
        </InfoCard>
    </div>
}


const OrgInfoEditForm = (props: any) => {
    const { data: focusAreaData, isFetching: focusAreaFetching, }
        = useQuery(['fetchFocusAreas', { key: 'focusArea', countryCode: 'US' }],
            service.getSpaceDonationConfigurations, { refetchOnWindowFocus: false });

    const { data: orgEntityData, isFetching: orgEntityFetching, }
        = useQuery(['fetchorgEntity', { key: 'orgEntity', countryCode: 'US' }],
            service.getSpaceDonationConfigurations, { refetchOnWindowFocus: false });


    const [orgName, setOrgName] = useState(props?.data?.orgName);
    const [orgAddressLine1, setOrgAddressLine1] = useState(props?.data?.orgAddressLine1);
    const [orgAddressLine2, setOrgAddressLine2] = useState(props?.data?.orgAddressLine2);
    const [orgState, setOrgState] = useState(props?.data?.orgState);
    const [orgCity, setOrgCity] = useState(props?.data?.orgCity);
    const [orgZip, setOrgZip] = useState(props?.data?.orgZip);

    const [focusArea] = useState(props?.data?.focusArea);
    const [orgEntity] = useState(props?.data?.orgEntity);
    const [website] = useState(props?.data?.website);


    const _mutation = useMutation((data: any) => service.updateOrgInfo(data));

    useEffect(() => {
        if (_mutation.isSuccess) {
            const myOrgs = props.userContext?.organization?.myOrgs;
            const userContactDetails = props.userContext?.organization?.userContactDetails;
            const updatedOrgData = { ..._mutation.data, myOrgs, userContactDetails }
            props.setEditButtonClicked(false);
            props.setData(_mutation.data);
            props.setShowSuccessPopUp(true);
            props.userContext.setOrg(updatedOrgData);
        }
    }, [_mutation.isSuccess]);

    useEffect(() => {
        if (_mutation.isError) {
            props.setShowErrorPopUp(true);
            props.setError(_mutation?.error);
        }
    }, [_mutation.isError]);

    const { control, handleSubmit, errors, setValue, getValues } = useForm({
        mode: 'onChange',
        defaultValues: {
            orgName: orgName ? orgName : '',
            orgAddressLine1: orgAddressLine1 ? orgAddressLine1 : '',
            orgAddressLine2: orgAddressLine2 ? orgAddressLine2 : '',
            orgState: orgState ? orgState : '',
            orgZip: orgZip ? orgZip : '',
            orgCity: orgCity ? orgCity : '',
            focusArea: focusArea ? focusArea : '',
            orgEntity: orgEntity ? orgEntity : '',
            website: website ? website : '',
        }
    });

    const onSubmit = () => {
        const updatedFields = getValues();
        const existingObj = props.data;
        const updatedObj = {
            ...existingObj,
            ...updatedFields
        }
        console.log(updatedObj);
        _mutation.mutate(updatedObj);
    };
    return <>
        {_mutation.isLoading && <LoaderComponent />}
        {(orgEntityFetching || focusAreaFetching) && <LoaderComponent />}
        <form onSubmit={handleSubmit(onSubmit)}>
            <InfoEditCard
                header='Your Organization'
                buttonSection={
                    <div className='account-info-card-button-section'>
                        <Button variant='tertiary' onClick={() => props.setEditButtonClicked(false)}>Cancel</Button>
                        <Button variant='primary' type='submit'>Update</Button>
                    </div>
                }
            >
                <InfoCardtextItem
                    header='Organization'>
                    <Controller
                        disabled={props.isOrgVerified}
                        control={control}
                        name='orgName'
                        defaultValue={''}
                        data-testid='orgName'
                        as={
                            <TextField variant='outlined' className='account-info-card-text-field'
                                value={<div className='account-info-card-form-text'>{orgName}</div>} required={true}
                                onChange={(event: any) => {
                                    setOrgName(event.target.value);
                                    setValue('orgName', event.target.value)
                                }} />
                        }
                        rules={{
                            required: 'Organization name is required',
                            validate: (value: any) => {
                                return isNotEmpty(value, 'Organization name is required')
                            }
                        }}
                    />
                    {errors?.orgName?.message && <span className='reg-form-error-container'
                        style={{ color: 'red' }}>{errors?.orgName?.message}</span>}
                </InfoCardtextItem>


                <InfoCardtextItem
                    header='Organization Type'>
                    <Controller
                        control={control}
                        name='focusArea'
                        defaultValue={focusArea}
                        data-testid='focusArea'
                        as={
                            <Select>
                                <option selected={true} value=''> --Select An Option-- </option>
                                {focusAreaData?.focusAreaList?.map((fa: any) => {
                                    return <option key={fa} value={fa}>
                                        {fa}
                                    </option>
                                })}
                            </Select>
                        }
                        rules={{
                            required: 'Organization Type is required',
                            validate: (value: any) => {
                                return isNotEmpty(value, 'Organization Type is required')
                            }
                        }}
                    />
                    {errors?.focusArea?.message && <span className='reg-form-error-container'
                        style={{ color: 'red' }}>{errors?.focusArea?.message}</span>}
                </InfoCardtextItem>
            { !props.isDeedUser &&  <InfoCardtextItem header='Organization Entity'>
                    <div>
                        <Controller
                            control={control}
                            name='orgEntity'
                            defaultValue={orgEntity}
                            data-testid='orgEntity'
                            as={
                                <Select>
                                    <option selected={true} value=''> --Select An Option-- </option>
                                    {orgEntityData?.orgEntityList?.map((fa: any) => {
                                        return <option key={fa} value={fa}>
                                            {fa}
                                        </option>
                                    })}
                                </Select>
                            }
                            rules={{
                                required: 'Organization Entity required',
                                validate: (value: any) => {
                                    return (isNotEmpty(value, 'Organization Entity is required'))
                                }
                            }}
                        />
                        {errors?.orgEntity?.message && <span className='reg-form-error-container'
                            style={{ color: 'red' }}>{errors?.orgEntity?.message}</span>}
                    </div>

                </InfoCardtextItem> }

                <InfoCardtextItem header='Organization Website'>
                    <div>
                        <Controller
                            control={control}
                            name='website'
                            defaultValue={''}
                            data-testid='website'
                            as={
                                <TextField
                                    className='account-info-card-text-field'
                                    variant='outlined'
                                    required={true}
                                    type='text'
                                />
                            }
                            rules={{
                                required: 'Organization website is required',
                                validate: (value: any) => {
                                    return isNotEmpty(value, 'Organization website')
                                }
                            }}
                        />
                        {errors?.website?.message && <span className='reg-form-error-container'
                            style={{ color: 'red' }}>{errors?.website?.message}</span>}
                    </div>
                </InfoCardtextItem>
                <InfoCardtextItem
                    header='Address Line 1'>
                    <Controller disabled={props.isDeedUser}
                        control={control}
                        name='orgAddressLine1'
                        defaultValue={''}
                        data-testid='orgAddressLine1'
                        as={
                            <TextField className='account-info-card-text-field'
                                variant='outlined' value={orgAddressLine1} required={true}
                                onChange={(event: any) => {
                                    setOrgAddressLine1(event.target.value);
                                    setValue('orgAddressLine1', event.target.value)
                                }} />
                        }
                        rules={{
                            required: 'Address Line 1 is required',
                            validate: (value: any) => {
                                return isNotEmpty(value, 'Address Line 1 name is required')
                            }
                        }}
                    />
                    {errors?.orgAddressLine1?.message && <span className='reg-form-error-container'
                        style={{ color: 'red' }}>{errors?.orgAddressLine1?.message}</span>}
                </InfoCardtextItem>

                <InfoCardtextItem
                    header='Address Line 2'>
                    <Controller disabled={props.isDeedUser}
                        control={control}
                        name='orgAddressLine2'
                        defaultValue={''}
                        data-testid='orgAddressLine2'
                        as={
                            <TextField variant='outlined' className='account-info-card-text-field'
                                value={orgAddressLine2} required={false}
                                onChange={(event: any) => {
                                    setOrgAddressLine2(event.target.value);
                                    setValue('orgAddressLine2', event.target.value)
                                }} />
                        }
                    />
                    {errors?.orgAddressLine2?.message && <span className='reg-form-error-container'
                        style={{ color: 'red' }}>{errors?.orgAddressLine2?.message}</span>}
                </InfoCardtextItem>

                <InfoCardtextItem
                    header='City'>
                    <Controller disabled={props.isDeedUser}
                        control={control}
                        name='orgCity'
                        defaultValue={''}
                        data-testid='orgCity'
                        as={
                            <TextField variant='outlined' className='account-info-card-text-field'
                                value={orgCity} required={true}
                                onChange={(event: any) => {
                                    setOrgCity(event.target.value);
                                    setValue('orgCity', event.target.value)
                                }} />
                        }
                        rules={{
                            required: 'City is required',
                            validate: (value: any) => {
                                return isNotEmpty(value, 'City name is required')
                            }
                        }}
                    />
                    {errors?.orgCity?.message && <span className='reg-form-error-container'
                        style={{ color: 'red' }}>{errors?.orgCity?.message}</span>}
                </InfoCardtextItem>

                <InfoCardtextItem
                    header='State'>
                    <Controller disabled={props.isDeedUser}
                        control={control}
                        name='orgState'
                        defaultValue={''}
                        data-testid='orgState'
                        as={
                            <TextField variant='outlined' className='account-info-card-text-field'
                                value={orgState} required={true}
                                onChange={(event: any) => {
                                    setOrgState(event.target.value);
                                    setValue('orgState', event.target.value)
                                }} />
                        }
                        rules={{
                            required: 'State is required',
                            validate: (value: any) => {
                                return isNotEmpty(value, 'State is required')
                            }
                        }}
                    />
                    {errors?.orgState?.message && <span className='reg-form-error-container'
                        style={{ color: 'red' }}>{errors?.orgState?.message}</span>}
                </InfoCardtextItem>

                <InfoCardtextItem
                    header='Zip'>
                    <Controller disabled={props.isDeedUser}
                        control={control}
                        name='orgZip'
                        defaultValue={''}
                        data-testid='orgZip'
                        as={
                            <TextField variant='outlined' className='account-info-card-text-field'
                                value={orgZip} required={true}
                                onChange={(event: any) => {
                                    setOrgZip(event.target.value);
                                    setValue('orgZip', event.target.value)
                                }} />
                        }
                        rules={{
                            required: 'Zip is required',
                            validate: (value: any) => {
                                return isNotEmpty(value, 'Zip is required')
                            }
                        }}
                    />
                    {errors?.orgZip?.message && <span className='reg-form-error-container'
                        style={{ color: 'red' }}>{errors?.orgZip?.message}</span>}
                </InfoCardtextItem>
            </InfoEditCard>

        </form>
    </>
}

const SecondaryInfoEditForm = (props: any) => {
    const myCId = props?.userContext?.organization?.userContactDetails?.cid;
    const [phonenumber] = useState(props?.data?.contactList.filter((x: any) => x.cid !== myCId)[0]?.phoneNumber);
    const [fname] = useState(props?.data?.contactList.filter((x: any) => x.cid !== myCId)[0]?.firstName);
    const [lname] = useState(props?.data?.contactList.filter((x: any) => x.cid !== myCId)[0]?.lastName);
    const [email] = useState(props?.data?.contactList.filter((x: any) => x.cid !== myCId)[0]?.email);


    const _mutation = useMutation((data: any) => service.updateOrgInfo(data));

    useEffect(() => {
        if (_mutation.isSuccess) {
            props.setEditButtonClicked(false);
            props.setData(_mutation.data);
            props.setShowSuccessPopUp(true);
        }
    }, [_mutation.isSuccess]);

    useEffect(() => {
        if (_mutation.isError) {
            props.setShowErrorPopUp(true);
            props.setError(_mutation?.error);
        }
    }, [_mutation.isError]);

    const { control, handleSubmit, errors, getValues, setValue } = useForm({
        mode: 'onChange',
        defaultValues: {
            phonenumber: phonenumber ? getPhoneNumber(phonenumber) : '',
            fname: fname ? fname : '',
            lname: lname ? lname : '',
            email: email ? email : ''
        }
    });

    const onSubmit = () => {
        const existingObj = props.data;
        const primaryContact = existingObj?.contactList
            ?.filter((x: any) => x.cid === props?.userContext?.organization?.userContactDetails?.cid)[0];
        const otherContacts = existingObj?.contactList
            ?.filter((x: any) => x.cid !== props?.userContext?.organization?.userContactDetails?.cid)[0];
        otherContacts.firstName = getValues().fname;
        otherContacts.lastName = getValues().lname;
        otherContacts.email = getValues().email;
        otherContacts.phoneNumber = getValues()?.phonenumber?.replace(/[^a-zA-Z0-9 ]/g, '')?.replace(/\s/g, '')?.substring(1);
        const updatedContactDetails = [primaryContact, otherContacts];
        existingObj.existingObj = updatedContactDetails;
        console.log(existingObj);
        _mutation.mutate(existingObj);
    };
    return <>
        {_mutation.isLoading && <LoaderComponent />}
        <form onSubmit={handleSubmit(onSubmit)}>
            <InfoEditCard
                header='Organization Secondary Contact Info'
                buttonSection={
                    <div className='account-info-card-button-section'>
                        <Button variant='tertiary' onClick={() => props.setEditButtonClicked(false)}>Cancel</Button>
                        <Button variant='primary' type='submit'>Update</Button>
                    </div>
                }
            >
                <InfoCardtextItem
                    header='First Name'>
                    <Controller
                        control={control}
                        name='fname'
                        defaultValue={''}
                        data-testid='fname'
                        as={
                            <TextField variant='outlined' className='account-info-card-text-field'
                                value={<div className='account-info-card-form-text'>{fname}</div>} required={true}
                            />
                        }
                        rules={{
                            required: 'First name is required',
                            validate: (value: any) => {
                                return isNotEmpty(value, 'First name is required')
                            }
                        }}
                    />
                    {errors?.fname?.message && <span className='reg-form-error-container'
                        style={{ color: 'red' }}>{errors?.fname?.message}</span>}
                </InfoCardtextItem>

                <InfoCardtextItem
                    header='Second Name'>
                    <Controller
                        control={control}
                        name='lname'
                        defaultValue={''}
                        data-testid='lname'
                        as={
                            <TextField className='account-info-card-text-field'
                                variant='outlined' value={lname} required={true}
                            />
                        }
                        rules={{
                            required: 'Second name is required',
                            validate: (value: any) => {
                                return isNotEmpty(value, 'Second name is required')
                            }
                        }}
                    />
                    {errors?.lname?.message && <span className='reg-form-error-container'
                        style={{ color: 'red' }}>{errors?.lname?.message}</span>}
                </InfoCardtextItem>

                <InfoCardtextItem
                    header='Email'>
                    <Controller
                        control={control}
                        name='email'
                        defaultValue={''}
                        data-testid='email'
                        as={
                            <TextField variant='outlined' className='account-info-card-text-field'
                                value={email} required={false} />
                        }
                        rules={{
                            required: 'Email is required',
                            validate: (value: any) => {
                                return validateEmail(value) ? true : 'invalid email'
                            }
                        }}
                    />
                    {errors?.email?.message && <span className='reg-form-error-container'
                        style={{ color: 'red' }}>{errors?.email?.message}</span>}
                </InfoCardtextItem>

                <InfoCardtextItem
                    header='Phone Number'>
                    <Controller
                        control={control}
                        name='phonenumber'
                        defaultValue={''}
                        data-testid='phonenumber'
                        as={
                            <TextField variant='outlined' className='account-info-card-text-field'
                                value={phonenumber} required={true} />
                        }
                        rules={{
                            required: 'Mobile Phone Number is required',
                            validate: (value: any) => {
                                return validatePhoneNumber(value, setValue, 'phonenumber');
                            }
                        }}
                    />
                    {errors?.phonenumber?.message && <span className='reg-form-error-container'
                        style={{ color: 'red' }}>{errors?.phonenumber?.message}</span>}
                </InfoCardtextItem>
            </InfoEditCard>

        </form>
    </>
}

const PrimaryInfoEditForm = (props: any) => {
    const myCId = props?.userContext?.organization?.userContactDetails?.cid;
    const [phonenumber] = useState(props?.data?.contactList.filter((x: any) => x.cid === myCId)[0]?.phoneNumber);
    const [fname] = useState(props?.data?.contactList.filter((x: any) => x.cid === myCId)[0]?.firstName);
    const [lname] = useState(props?.data?.contactList.filter((x: any) => x.cid === myCId)[0]?.lastName);
    const [email] = useState(props?.data?.contactList.filter((x: any) => x.cid === myCId)[0]?.email);


    const _mutation = useMutation((data: any) => service.updateOrgInfo(data));

    useEffect(() => {
        if (_mutation.isSuccess) {
            props.setEditButtonClicked(false);
            props.setData(_mutation.data);
            props.setShowSuccessPopUp(true);
        }
    }, [_mutation.isSuccess]);

    useEffect(() => {
        if (_mutation.isError) {
            props.setShowErrorPopUp(true);
            props.setError(_mutation?.error);
        }
    }, [_mutation.isError]);

    const { control, handleSubmit, errors, getValues, setValue } = useForm({
        mode: 'onChange',
        defaultValues: {
            phonenumber: phonenumber ? getPhoneNumber(phonenumber) : '',
            fname: fname ? fname : '',
            lname: lname ? lname : '',
            email: email ? email : ''
        }
    });

    const onSubmit = () => {
        const existingObj = props.data;
        const primaryContact = existingObj?.contactList
            ?.filter((x: any) => x.cid === props?.userContext?.organization?.userContactDetails?.cid)[0];
        const otherContacts = existingObj?.contactList
            ?.filter((x: any) => x.cid !== props?.userContext?.organization?.userContactDetails?.cid);
        primaryContact.phoneNumber = getValues()?.phonenumber?.replace(/[^a-zA-Z0-9 ]/g, '')?.replace(/\s/g, '')?.substring(1);
        const updatedContactDetails = [primaryContact, ...otherContacts];
        existingObj.existingObj = updatedContactDetails;
        console.log(existingObj);
        _mutation.mutate(existingObj);
    };
    return <>
        {_mutation.isLoading && <LoaderComponent />}
        <form onSubmit={handleSubmit(onSubmit)}>
            <InfoEditCard
                header='Your Primary info'
                buttonSection={
                    <div className='account-info-card-button-section'>
                        <Button variant='tertiary' onClick={() => props.setEditButtonClicked(false)}>Cancel</Button>
                        <Button variant='primary' type='submit'>Update</Button>
                    </div>
                }
            >
                <InfoCardtextItem
                    header='First Name'>
                    <Controller
                        disabled={true}
                        control={control}
                        name='fname'
                        defaultValue={''}
                        data-testid='fname'
                        as={
                            <TextField variant='outlined' className='account-info-card-text-field'
                                value={<div className='account-info-card-form-text'>{fname}</div>} required={true}
                            />
                        }
                        rules={{
                            required: 'First name is required',
                            validate: (value: any) => {
                                return isNotEmpty(value, 'First name is required')
                            }
                        }}
                    />
                    {errors?.fname?.message && <span className='reg-form-error-container'
                        style={{ color: 'red' }}>{errors?.fname?.message}</span>}
                </InfoCardtextItem>

                <InfoCardtextItem
                    header='Second Name'>
                    <Controller
                        disabled={true}
                        control={control}
                        name='lname'
                        defaultValue={''}
                        data-testid='lname'
                        as={
                            <TextField className='account-info-card-text-field'
                                variant='outlined' value={lname} required={true}
                            />
                        }
                        rules={{
                            required: 'Second name is required',
                            validate: (value: any) => {
                                return isNotEmpty(value, 'Second name is required')
                            }
                        }}
                    />
                    {errors?.lname?.message && <span className='reg-form-error-container'
                        style={{ color: 'red' }}>{errors?.lname?.message}</span>}
                </InfoCardtextItem>

                <InfoCardtextItem
                    header='Email'>
                    <Controller
                        disabled={true}
                        control={control}
                        name='email'
                        defaultValue={''}
                        data-testid='email'
                        as={
                            <TextField variant='outlined' className='account-info-card-text-field'
                                value={email} required={false} />
                        }
                        rules={{
                            required: 'Email is required',
                            validate: (value: any) => {
                                return validateEmail(value) ? true : 'invalid email'
                            }
                        }}
                    />
                    {errors?.email?.message && <span className='reg-form-error-container'
                        style={{ color: 'red' }}>{errors?.email?.message}</span>}
                </InfoCardtextItem>

                <InfoCardtextItem
                    header='Phone Number'>
                    <Controller
                        control={control}
                        name='phonenumber'
                        defaultValue={''}
                        data-testid='phonenumber'
                        as={
                            <TextField variant='outlined' className='account-info-card-text-field'
                                value={phonenumber} required={true} />
                        }
                        rules={{
                            required: 'Mobile Phone Number is required',
                            validate: (value: any) => {
                                return validatePhoneNumber(value, setValue, 'phonenumber');
                             }
                        }}
                    />
                    {errors?.phonenumber?.message && <span className='reg-form-error-container'
                        style={{ color: 'red' }}>{errors?.phonenumber?.message}</span>}
                </InfoCardtextItem>
            </InfoEditCard>

        </form>
    </>
}


const SuccessModalCard: any = (props: any) => {
    return <Modal isOpen={props.isOpen} onClose={() => props.setIsOpen(false)}
        size='small' title=' '>
        <div className='checkin-message'>
            <CheckCircle className='success-icon' />
            <h3> Success!</h3>
            <br />
            <br />
            <Button
                onClick={() => props.setIsOpen(false)} variant='secondary'>
                Back
            </Button>
        </div>
    </Modal>
}



const SuccessConfirm: any = (props: any) => {
    return <Modal isOpen={props.isOpen} onClose={() => window.location.reload()}
    size='small' title=' '>
    <div className='checkin-message'>
        <CheckCircle className='success-icon' />
        <h3> Success!</h3>
        <br />
        <br />
        <Button
            onClick={() => window.location.reload()} variant='secondary'>
            Back
        </Button>
    </div>
</Modal>
}

const ErrorModalCard: any = (props: any) => {
    return <Modal isOpen={props.isOpen} onClose={() => props.setIsOpen(false)}
        size='small' title=' '>
        <div className='checkin-message'>
            <CloseCircleFill className='error-icon' />
            <h3> Error!</h3>
            <br />
            {props.error?.response?.data?.errorMessage}
            <br />
            <Button
                onClick={() => props.setIsOpen(false)} variant='secondary'>
                Back
            </Button>
        </div>
    </Modal>
}
